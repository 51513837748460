<template>
  <div class="map-wrapper">
    <GmapMap ref="map" :center="center" :zoom="9" map-type-id="roadmap" style="width: 100%; height: 100%"> </GmapMap>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { getGoogleMapsAPI } from 'gmap-vue';

export default {
  name: 'GoogleMapWithDrawing',
  props: {
    editedPricingZoneItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      google: null,
      center: { lat: 1.1, lng: 1.1 },
      drawingManager: null,
      selectedAreas: [], // Store multiple shapes
      overlayCompleteListener: null, // Store the listener reference
    };
  },
  created() {
    this.loadMap();
  },
  beforeDestroy() {
    if (this.overlayCompleteListener) {
      this.google.maps.event.removeListener(this.overlayCompleteListener);
    }
  },
  methods: {
    async waitForGoogleMapsAPI() {
      return new Promise((resolve) => {
        const checkExist = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100);
      });
    },

    async loadMap() {
      try {
        await this.waitForGoogleMapsAPI();
        this.google = await getGoogleMapsAPI();
        await this.getCountryCoordinates(); // Set initial center based on country
        this.initializeDrawingManager();
        this.displaySavedShapes();
      } catch (error) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    async getCountryCoordinates() {
      const user = this.$store.getters['auth/user'];

      this.$store
        .dispatch('googleMapsAPI/getCountryLocation', {
          country: user.organisation.country.name,
        })
        .then((data) => {
          const location = data.results[0].geometry.location;
          this.center = { lat: location.lat, lng: location.lng };
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    initializeDrawingManager() {
      if (this.google && this.google.maps && this.google.maps.drawing) {
        this.drawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.circle,
          drawingControl: true,
          drawingControlOptions: {
            position: this.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              this.google.maps.drawing.OverlayType.CIRCLE,
              this.google.maps.drawing.OverlayType.RECTANGLE,
              this.google.maps.drawing.OverlayType.POLYGON,
            ],
          },
          circleOptions: {
            fillColor: '#FF0000',
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: true,
            editable: true,
            zIndex: 1,
          },
          rectangleOptions: {
            fillColor: '#FF0000',
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: true,
            editable: true,
            zIndex: 1,
          },
        });

        this.drawingManager.setMap(this.$refs.map.$mapObject);

        // Store the listener reference for later removal
        this.overlayCompleteListener = this.google.maps.event.addListener(
          this.drawingManager,
          'overlaycomplete',
          (event) => {
            const newArea = event.overlay;
            this.selectedAreas.push(newArea);

            this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
          }
        );
      } else {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    selectedAreasData() {
      return this.selectedAreas.map((shape) => {
        if (shape instanceof this.google.maps.Circle) {
          return {
            type: 'circle',
            center: { lat: shape.getCenter().lat(), lng: shape.getCenter().lng() },
            radius: shape.getRadius(),
          };
        } else if (shape instanceof this.google.maps.Rectangle) {
          const bounds = shape.getBounds();
          return {
            type: 'rectangle',
            northEast: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
            southWest: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
          };
        } else if (shape instanceof this.google.maps.Polygon) {
          const path = shape
            .getPath()
            .getArray()
            .map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            }));
          return {
            type: 'polygon',
            path,
          };
        }
      });
    },

    displaySavedShapes() {
      if (this.editedPricingZoneItem.value) {
        const shapes = JSON.parse(this.editedPricingZoneItem.value);
        shapes.forEach((shape) => {
          const { type, center, radius, northEast, southWest, path } = shape;
          let mapShape;

          if (type === 'circle' && center && radius) {
            mapShape = new this.google.maps.Circle({
              center,
              radius,
              map: this.$refs.map.$mapObject,
              fillColor: '#FF0000',
              fillOpacity: 0.3,
              strokeWeight: 1,
              clickable: true,
              editable: true,
              zIndex: 1,
            });
          } else if (type === 'rectangle' && northEast && southWest) {
            mapShape = new this.google.maps.Rectangle({
              bounds: {
                north: northEast.lat,
                east: northEast.lng,
                south: southWest.lat,
                west: southWest.lng,
              },
              map: this.$refs.map.$mapObject,
              fillColor: '#FF0000',
              fillOpacity: 0.3,
              strokeWeight: 1,
              clickable: true,
              editable: true,
              zIndex: 1,
            });
          } else if (type === 'polygon' && path) {
            mapShape = new this.google.maps.Polygon({
              paths: path,
              map: this.$refs.map.$mapObject,
              fillColor: '#FF0000',
              fillOpacity: 0.3,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 1,
              clickable: true,
              editable: true,
              zIndex: 1,
            });
          }
          if (mapShape) this.selectedAreas.push(mapShape);
        });
      }
    },
  },
};
</script>

<style>
.map-wrapper {
  position: relative;
  height: 400px;
  width: 100%;
}
</style>
