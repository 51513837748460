<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.pricingZonesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="pricingZonesHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="pricingZones"
            :search="searchPricingZoneString"
            class="elevation-1 cursor-pointer"
            @click:row="editPricingZoneItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.pricingZones') }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchPricingZoneString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="pricingZoneDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(pricingZoneFormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingZoneEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(savePricingZone)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                  <v-text-field
                                    v-model="editedPricingZoneItem.name"
                                    autofocus
                                    clearable
                                    counter="200"
                                    :label="$t('pricingZonesPage.name')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="code">
                                  <v-text-field
                                    v-model="editedPricingZoneItem.code"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingZonesPage.code')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required" v-slot="{ errors }" name="coordinates">
                                  <google-map-drawing
                                    :key="pricingZoneDialog"
                                    :editedPricingZoneItem="editedPricingZoneItem"
                                    @saveCoordinates="saveCoordinates" />
                                  <v-text-field
                                    hidden
                                    class="text-field-no-border"
                                    v-model="editedPricingZoneItem.value"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="pricingZoneDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedPricingZoneItem.name }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingZoneDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('pricingZonesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!enablePricingZoneSave"
                        class="primary"
                        text
                        type="submit"
                        @click="deletePricingZoneItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="editPricingZoneItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.edit') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { pricingZonesHeaders } from '@/mixins/data-table-headers';
import { defaultPricingZone } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import GoogleMapDrawing from '@/components/zone-pricing/GoogleMapDrawing.vue';

export default {
  name: 'PricingZonesPage',
  components: { ButtonSubmit, GoogleMapDrawing },
  data: () => ({
    searchPricingZoneString: '',
    pricingZoneDialog: false,
    pricingZoneDialogDelete: false,
    pricingZones: [],
    pricingZoneFormTitle: 'pricingZonesPage.newItem',
    pricingZoneFormValid: true,
    editedPricingZoneItem: {},
    enablePricingZoneSave: true,
    loading: false,
  }),
  created() {
    this.editedPricingZoneItem = Object.assign({}, defaultPricingZone);
    this.loadAllPricingZones();
  },
  computed: {
    pricingZonesHeaders() {
      return pricingZonesHeaders(i18n);
    },
  },
  methods: {
    async loadAllPricingZones() {
      await this.$store.dispatch('zonePricing/getAllPricingZones').then((res) => {
        this.pricingZones = res.data.map((e) => {
          return e;
        });
      });
    },

    editPricingZoneItem(item) {
      this.editedPricingZoneItem = this.pricingZones.indexOf(item);
      this.editedPricingZoneItem = Object.assign({}, item);
      this.pricingZoneFormTitle = item.name;
      this.pricingZoneDialog = true;
    },

    deleteItem(item) {
      this.editedPricingZoneItem = this.pricingZones.indexOf(item);
      this.editedPricingZoneItem = Object.assign({}, item);
      this.pricingZoneDialogDelete = true;
    },

    async savePricingZone() {
      this.enablePricingZoneSave = false;
      this.loading = true;
      let path = 'savePricingZone';

      if (this.editedPricingZoneItem.id) {
        Object.assign(this.editedPricingZoneItem, { _method: 'POST' });
        path = 'updatePricingZone';
      }

      await this.$store
        .dispatch('zonePricing/' + path, this.editedPricingZoneItem)
        .then(() => {
          this.loadAllPricingZones();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closePricingZoneEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enablePricingZoneSave = true;
          this.loading = false;
          this.pricingZoneFormTitle = 'pricingZonesPage.newItem';
        });
    },

    async deletePricingZoneItemConfirm() {
      await this.$store
        .dispatch('zonePricing/deletePricingZone', this.editedPricingZoneItem)
        .then(() => {
          this.loadAllPricingZones();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.closePricingZoneDelete();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closePricingZoneDelete();
        });
    },

    closePricingZoneEdit() {
      this.pricingZoneDialog = false;
      this.$nextTick(() => {
        this.editedPricingZoneItem = Object.assign({}, defaultPricingZone);
      });
      this.$refs.form.reset();
      this.pricingZoneFormTitle = 'pricingZonesPage.newItem';
    },

    closePricingZoneDelete() {
      this.pricingZoneDialogDelete = false;
      this.$nextTick(() => {
        this.editedPricingZoneItem = Object.assign({}, defaultPricingZone);
      });
    },

    saveCoordinates(coordinates) {
      this.editedPricingZoneItem = { ...this.editedPricingZoneItem, value: coordinates };
    },
  },

  watch: {
    pricingZoneDialog(val) {
      val || this.closePricingZoneEdit();
    },
  },
};
</script>

<style>
.text-field-no-border .v-input__control .v-input__slot::before {
  border: 0 !important;
}
</style>
